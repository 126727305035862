import { useState } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { Icons } from '@stacc/flow-ui-components'
import { FontStyles, Colors } from '@stacc/flow-ui-components'
import { SecondaryButton } from '@stacc/flow-ui-components'
import { Modal } from '@stacc/flow-ui-components'

import FileDisplay from './FileDisplay'
import RemovedEsignTask from './RemovedEsignTask'
import fileService from '../../services/files'
import downloadLink from '../../helpers/downloadLink'
import { CompletedtTaskProps } from '../../types/common/common'

const TaskContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  ${FontStyles.LargeStrong};
`

const TaskDescription = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;

  p {
    margin: 0;
    padding-left: 20px;
  }
`

const ButtonGroup = styled.div`
  button {
    margin-left: 10px;
  }
`

const ESignButton = styled(SecondaryButton)`
  color: ${Colors.Grass};
  border: 2px solid ${Colors.GrassLight};
  &:hover {
    border: 2px solid ${Colors.GrassLight};
  }
`

const CompletedTask = ({
  title,
  actionTitle,
  taskType,
  context,
  data,
  t
}: CompletedtTaskProps) => {
  const [displayTask, setDisplayTask] = useState(false)

  function onDownloadClick() {
    const { fileId } = context

    fileService.getFileMetadata(fileId).then((response) => {
      if (!response) {
        console.error('Error getting file meta data')
        return
      }

      const metadata = response.data
      const { filename } = metadata

      fileService
        .getFile(fileId)
        .then((result) => downloadLink(result?.file, filename))
    })
  }
  const { fileId } = context

  const isEsignTask =
    taskType === 'dc-e-sign-document' ||
    taskType === 'dcse-e-sign-document' ||
    taskType === 'dcse-externally-sign-document'
  if (isEsignTask && data.status === 'removed') {
    return <RemovedEsignTask title={title} actionTitle={actionTitle} />
  }

  const shouldDisplaySuccess = taskType !== 'cc-upload-document'

  return (
    <div>
      <TaskContainer>
        <TaskDescription>
          {shouldDisplaySuccess ? (
            <Icons.SuccessCircle />
          ) : (
            <Icons.ActiveCircle />
          )}
          <p>{title}</p>
        </TaskDescription>
        {fileId && (
          <ButtonGroup>
            <SecondaryButton onClick={() => onDownloadClick()}>
              {t('Download')}
            </SecondaryButton>
            <SecondaryButton onClick={() => setDisplayTask(!displayTask)}>
              {actionTitle}
            </SecondaryButton>
          </ButtonGroup>
        )}
        {isEsignTask && <ESignButton disabled>{actionTitle}</ESignButton>}
      </TaskContainer>
      {displayTask && (
        <Modal title={title} onClose={() => setDisplayTask(false)}>
          <FileDisplay fileId={fileId} />
        </Modal>
      )}
    </div>
  )
}

export default withTranslation('inbox')(CompletedTask)

import styled from 'styled-components'
import { Icons } from '@stacc/flow-ui-components'
import { Link } from 'react-router-dom'
import Stakeholder from './Stakeholder'
import { StakeholderProps } from '../../types/adminDealers'

const TrashButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`

const EditButton = styled.button`
  position: absolute;
  top: 10px;
  right: 50px;
`

const UserResult = ({ stakeholder }: StakeholderProps) => (
  <>
    <Stakeholder stakeholder={stakeholder} />
    <Link to={`/admin/dealers/user/${stakeholder.id}`}>
      <EditButton>
        <Icons.Edit color="black" />
      </EditButton>
    </Link>
    <Link to={`/admin/dealers/delete/${stakeholder.id}`}>
      <TrashButton>
        <Icons.Trashcan color="black" />
      </TrashButton>
    </Link>
  </>
)

export default UserResult

import { useState, useEffect } from 'react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'

import CaseSummaryInfo from './CaseSummaryInfo'
import CaseSummaryObject from './CaseSummaryObject'
import { Chevron } from '../../icons/chevron'
import clsx from 'clsx'
import { TFunction } from 'i18next'

type CollapsibleSectionProps = {
  asset: any
  financialInfo: any
  hasAssetInfo: boolean
  t: TFunction
  ncvsID: number
}

function CollapsibleSection({
  asset,
  financialInfo,
  hasAssetInfo,
  t,
  ncvsID
}: CollapsibleSectionProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    const savedState = localStorage.getItem('isCaseSummaryCollapsed')
    if (savedState !== null) {
      setIsCollapsed(JSON.parse(savedState))
    }
    setIsLoaded(true)
  }, [])

  const toggleCollapse = () => {
    setIsCollapsed((prevState) => {
      const newState = !prevState
      localStorage.setItem('isCaseSummaryCollapsed', JSON.stringify(newState))
      return newState
    })
  }

  if (!isLoaded) {
    return (
      <div className="flex justify-center text-sm text-slate-600">
        Loading...
      </div>
    )
  }

  return (
    <Disclosure as="div" defaultOpen={isCollapsed} className="mt-2">
      <div
        className={clsx(
          'overflow-hidden transition-all duration-200 ease-in-out',
          !isCollapsed ? 'max-h-[20px]' : 'max-h-[999px]'
        )}
      >
        <DisclosureButton
          className="group flex w-full items-center justify-center gap-2"
          onClick={toggleCollapse}
        >
          <span className="text-sm/6 font-medium text-slate-700 group-data-[hover]:text-black/80">
            {isCollapsed
              ? t('hide-additional-details')
              : t('show-additional-details')}
          </span>
          <Chevron
            className={clsx(
              isCollapsed ? 'rotate-180' : 'rotate-0',
              'transform duration-150 ease-in-out'
            )}
            size={20}
          />
        </DisclosureButton>
        <DisclosurePanel
          transition
          className="origin-top transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        >
          <div className="grid grid-cols-12 gap-4 mt-4">
            {asset && hasAssetInfo && (
              <div
                className={`p-4 row-span-1 rounded-xl shadow-lg flex flex-col w-full border border-gray-300 ${
                  financialInfo ? 'col-span-4' : 'col-span-12'
                }`}
              >
                <CaseSummaryObject asset={asset} t={t} />
              </div>
            )}
            {financialInfo && (
              <div
                className={`p-4 row-span-1 rounded-xl shadow-lg flex flex-col w-full border border-gray-300 ${
                  hasAssetInfo ? 'col-span-8' : 'col-span-12'
                }`}
              >
                <CaseSummaryInfo
                  financialInfo={financialInfo}
                  t={t}
                  ncvsID={ncvsID}
                />
              </div>
            )}
          </div>
        </DisclosurePanel>
      </div>
    </Disclosure>
  )
}

export default CollapsibleSection

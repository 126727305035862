import { TFunction } from 'i18next'
import ProgressBarItem from './ProgressBarItem'

type CaseSummaryProgressBarProps = {
  businessStatus: string
  contractStatus: string
  subContractStatus: string
  documentRequirements: any
  t: TFunction
  flowDefinitionId: string
  externalRequiredDocumentsOk: boolean
  allDocumentsAreOk: boolean
  hasCustomerDeclarationRequirement: boolean
  hasSourceOfFundsRequirement: boolean
  hasCoCustomer: boolean
}

const Line = (status: string) => {
  switch (status) {
    case 'in-progress':
      return <div className={`bg-yellow-500 w-full h-1 rounded-full`}></div>
    case 'rejected':
      return <div className={`bg-red-500 w-full h-1 rounded-full`}></div>
    case 'completed':
      return <div className={`bg-blue-500 w-full h-1 rounded-full`}></div>
    default:
      return <div className={`bg-gray-300 w-full h-1 rounded-full`}></div>
  }
}

function CaseSummaryProgressBarNO({
  businessStatus,
  contractStatus,
  subContractStatus,
  documentRequirements,
  flowDefinitionId,
  externalRequiredDocumentsOk,
  allDocumentsAreOk,
  hasCustomerDeclarationRequirement,
  hasSourceOfFundsRequirement,
  hasCoCustomer,
  t
}: CaseSummaryProgressBarProps) {
  const eSignDocuments: any[] = documentRequirements
    ? Object.entries(documentRequirements)
        .map(([docName, docData]) => {
          if ((docData as any).toBeEsigned) {
            return {
              name: docName,
              dateSigned: (docData as any).dateSigned
                ? new Date((docData as any).dateSigned).toLocaleDateString()
                : ''
            }
          }
          return null
        })
        .filter(Boolean)
    : []

  const customerDeclaration = documentRequirements?.CUSTOMER_DECLARATION
  const coCustomerDeclaration = documentRequirements?.CO_CUSTOMER_DECLARATION
  const sourceOfFunds = documentRequirements?.SOURCE_OF_FUNDS

  const check_KYC_SOF = () => {
    if (hasCustomerDeclarationRequirement) {
      if (
        !customerDeclaration ||
        (customerDeclaration.status.toLowerCase() !== 'approved' &&
          customerDeclaration.status.toLowerCase() !== 'uploaded')
      ) {
        return false
      }
    }

    if (hasSourceOfFundsRequirement) {
      if (
        !sourceOfFunds ||
        (sourceOfFunds.status.toLowerCase() !== 'approved' &&
          sourceOfFunds.status.toLowerCase() !== 'uploaded')
      ) {
        return false
      }
    }

    if (hasCoCustomer) {
      if (
        coCustomerDeclaration &&
        coCustomerDeclaration.status.toLowerCase() !== 'approved' &&
        coCustomerDeclaration.status.toLowerCase() !== 'uploaded'
      ) {
        return false
      }
    }

    return true
  }

  let creditState: any = 'in-progress'

  // contract status rejected
  creditState =
    contractStatus && contractStatus === 'rejected' ? 'rejected' : creditState

  // contract status trial
  creditState =
    contractStatus && contractStatus === 'trial' ? 'trial' : creditState

  // subcontract status consent sent
  creditState =
    subContractStatus === 'consent sent' ? 'in-progress' : creditState

  // contract status approved/finalized
  creditState =
    contractStatus &&
    (contractStatus === 'approved' || contractStatus === 'finalized')
      ? 'finalized'
      : creditState

  // Incoming - KYC/SOF - until customer declaration and SOF are uploaded
  let KYC_SOFStatus: any = 'not-started'

  KYC_SOFStatus =
    !check_KYC_SOF() &&
    contractStatus === 'finalized' &&
    flowDefinitionId !== 'credit-check'
      ? 'in-progress'
      : KYC_SOFStatus

  KYC_SOFStatus =
    check_KYC_SOF() &&
    contractStatus === 'finalized' &&
    flowDefinitionId !== 'credit-check'
      ? 'completed'
      : KYC_SOFStatus

  //Incoming/finalized - KYC/SOF and Document signing
  let signingStatus: any = 'not-started'

  const allDocumentsSigned = eSignDocuments?.every(
    (doc) => doc && doc.dateSigned !== ''
  )

  signingStatus =
    contractStatus &&
    contractStatus == 'finalized' &&
    flowDefinitionId == 'document-control' &&
    !allDocumentsSigned
      ? 'in-progress'
      : signingStatus

  signingStatus =
    contractStatus && contractStatus == 'finalized' && allDocumentsSigned
      ? 'completed'
      : signingStatus

  //inbox/Control - Document control

  let progress = 0

  progress =
    contractStatus &&
    contractStatus == 'finalized' &&
    allDocumentsSigned &&
    externalRequiredDocumentsOk &&
    flowDefinitionId == 'document-control' &&
    !allDocumentsAreOk
      ? 1
      : progress

  //Audit
  progress = businessStatus && businessStatus === 'audit' ? 2 : progress

  //Select post control/Post control - Post control
  progress =
    (businessStatus && businessStatus === 'post-control') ||
    businessStatus === 'select-post-control'
      ? 3
      : progress

  //Archiving
  progress = businessStatus && businessStatus === 'archiving' ? 4 : progress

  //completed
  progress = businessStatus && businessStatus === 'completed' ? 5 : progress

  const status = (step: number) => {
    return progress > step
      ? 'completed'
      : progress === step
      ? contractStatus === 'rejected'
        ? 'rejected'
        : 'in-progress'
      : 'not-started'
  }

  const hoverComponentSignedDocuments = () => {
    if (eSignDocuments?.length === 0) {
      return null
    }

    return (
      <ul>
        {(eSignDocuments as { name: string; dateSigned: string }[]).map(
          ({ name, dateSigned }) => (
            <li key={name} className="text-xs">
              <span className="text-gray-800">{t(name)}</span>
              <span className="text-gray-500">
                {dateSigned
                  ? ` - ${t('progress-bar-signed-at')}: ${dateSigned}`
                  : ` - ${t('progress-bar-not-signed')}`}
              </span>
            </li>
          )
        )}
      </ul>
    )
  }

  return (
    <div className="flex h-10 justify-center items-center space-x-4 px-10 pb-8">
      {/* Credit status */}
      <ProgressBarItem status={creditState} label={t('progress-bar-credit')} />
      {/* KYC/SOF */}
      {Line(KYC_SOFStatus)}
      <ProgressBarItem status={KYC_SOFStatus} label="KYC/SOF" />
      {/* Document signing */}
      {Line(signingStatus)}
      <ProgressBarItem
        status={signingStatus}
        hoverChild={hoverComponentSignedDocuments()}
        label={t('progress-bar-document-signing')}
      />
      {/*Document control */}
      {Line(status(1))}
      <ProgressBarItem
        status={status(1)}
        label={t('progress-bar-document-control')}
      />
      {/* Audit */}
      {Line(status(2))}
      <ProgressBarItem status={status(2)} label={t('progress-bar-audit')} />
      {/* Post control */}
      {Line(status(3))}
      <ProgressBarItem
        status={status(3)}
        label={t('progress-bar-post-control')}
      />
      {/* Archiving */}
      {Line(status(4))}
      <ProgressBarItem status={status(4)} label={t('progress-bar-archiving')} />
    </div>
  )
}

export default CaseSummaryProgressBarNO

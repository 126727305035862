import React, { useState } from 'react'
import styled from 'styled-components'

import { withTranslation } from 'react-i18next'

import { Colors, FontStyles } from '@stacc/flow-ui-components'
import { RadioButtons } from '@stacc/flow-ui-components'
import { AuditFormProps } from '../../../types/audit'

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: 1px solid ${Colors.Sea};
  box-sizing: border-box;
  border-radius: 4px;
  ${FontStyles.Normal};
  padding: 10px;
`

const FormGroup = styled.div`
  padding: 10px 0px 0px 0px;
`

const Error = styled.p`
  color: ${Colors.Ferrari};
`

const AuditForm = (props: AuditFormProps) => {
  const {
    formKey,
    t,
    onAuditChange,
    onCommentChange,
    audit,
    comment,
    onValidationSuccess,
    submitButtonRef
  } = props
  const [validationError, setValidationError] = useState({
    validationError: null
  })

  React.useEffect(() => {
    if (props.formKey !== formKey) {
      setValidationError({ validationError: null })
    }

    return () => {
      setValidationError({ validationError: null })
    }
  }, [formKey])

  const radioOptions = [
    { label: t('Approve'), value: 'approved' },
    { label: t('Reject'), value: 'rejected' }
  ]

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setValidationError({ validationError: null })
    if (name === 'audit') {
      onAuditChange(value)
    }
    // Comment is pushed on blur because we do not need it to update on each letter typing..
  }

  const onCommentBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    const { value } = e.target
    onCommentChange(value)
  }

  // TODO: Find a good solution for the type issue on onSubmit.
  const onSubmit = (e: any) => {
    e.preventDefault()
    const auditValue = e.target.elements.audit.value
    const commentValue = e.target.elements.comment.value

    if (
      auditValue &&
      auditValue !== 'approved' &&
      (!commentValue || commentValue.length < 1)
    ) {
      setValidationError({ validationError: t('Please provide reasons') })
      return false
    }

    setValidationError({ validationError: null })
    onValidationSuccess()
    return true
  }

  return (
    <div>
      <h6>{t('Decision')}</h6>
      <form key={formKey} onSubmit={onSubmit}>
        <FormGroup>
          <RadioButtons
            name="audit"
            options={radioOptions}
            onChange={onChange}
            checkedValue={audit}
          />
        </FormGroup>
        <FormGroup>
          <TextArea
            name="comment"
            rows={5}
            placeholder={t('Optionally reasons')}
            defaultValue={comment}
            onChange={onChange}
            onBlur={onCommentBlur}
          />
        </FormGroup>
        <button
          style={{ display: 'none' }}
          ref={submitButtonRef}
          type="submit"
        />
        {validationError && <Error>{validationError.validationError}</Error>}
      </form>
    </div>
  )
}

export default withTranslation('inbox')(AuditForm)

import { useQuery } from '@tanstack/react-query'
import { CenteredSpinner } from '@stacc/flow-ui-components'
import { DocumentsCard } from './DocumentCard'
import { TaskProps } from './TaskPropsTypes'
import { FlowContext } from './useFlowContext'
import { useDropzone } from 'react-dropzone'
import { Modal } from '@stacc/flow-ui-components'
import fileService from '../../services/files'
import { useState } from 'react'
import clsx from 'clsx'
import { UploadedDocumentHandler } from './UploadedDocumentHandler'

const MAX_SIZE = 20 * 1024 * 1024

export function Files(props: TaskProps) {
  const { flow, t } = props
  const { flowId } = flow

  const [file, setFile] = useState([])
  const [modal, setModal] = useState(false)
  const [isDragOver, setIsDragOver] = useState(0)

  function handleFileUpload(file: any) {
    setFile(file)
    setModal(true)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      handleFileUpload(
        //@ts-ignore
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
    },
    accept: {
      'application/pdf': [],
      'image/*': []
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: MAX_SIZE
  })
  const { data: uploadedFiles, isFetching } = useQuery({
    queryKey: [
      'files',
      flowId,
      flow.data.documentRequirements,
      flow.data.internalFiles
    ],
    queryFn: async () => {
      const { documentRequirements, internalFiles, storedFiles } = flow.data
      const fetchFiles = async (filesSource: any, typeKey: string) => {
        type uploadedByType = 'customer' | 'caseworker' | 'dealer'
        let uploadedBy: uploadedByType

        let files: any[] = []
        for (const key in filesSource) {
          const document = filesSource[key]
          uploadedBy = 'caseworker'
          if (document.fileId) {
            try {
              if (document.uploadedBySub) {
                uploadedBy = 'customer'
              }

              if (document.isUploadedByDealer) {
                uploadedBy = 'dealer'
              }

              const file = await fileService.getFile(document.fileId)
              const metadata = await fileService.getFileMetadata(
                document.fileId
              )
              files.push({
                file: file?.file,
                mimeType: file?.type,
                type: typeKey === 'key' ? key : document.type,
                fileName: metadata?.data.filename,
                created: metadata?.data.created,
                fileId: document.fileId,
                uploadedBy
              })
            } catch (err) {}
          }
        }
        return files
      }

      const documentFilesFormat = await fetchFiles(documentRequirements, 'key')
      const internalFilesFormat = await fetchFiles(internalFiles, 'type')

      return {
        flowId,
        files: documentFilesFormat,
        internalFiles: internalFilesFormat
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  })

  const customerDocuments = uploadedFiles?.files.filter(
    (file: any) => file.uploadedBy === 'customer'
  )

  const caseWorkerDocuments = [
    ...(uploadedFiles?.files || []).filter(
      (file: any) => file.uploadedBy === 'caseworker'
    ),
    ...(uploadedFiles?.internalFiles || [])
  ]

  const dealerDocuments = uploadedFiles?.files.filter(
    (file: any) => file.uploadedBy === 'dealer'
  )

  const remainingDocuments = uploadedFiles?.files.filter(
    (file: any) =>
      file.uploadedBy !== 'customer' &&
      file.uploadedBy !== 'caseworker' &&
      file.uploadedBy !== 'dealer'
  )

  const { ...rootProps } = getRootProps()

  if (isFetching) return <CenteredSpinner />

  return (
    <FlowContext.Provider value={props}>
      <div
        className="relative flex flex-col gap-5"
        onDragEnter={() => setIsDragOver((current) => current + 1)}
        onDragLeave={() => setIsDragOver((current) => current - 1)}
      >
        {isDragOver > 0 && (
          <div
            {...rootProps}
            className={clsx(
              'flex justify-center items-center absolute w-full h-full rounded-xl border-2 border-dashed border-gray-400 backdrop-filter backdrop-blur-lg shadow-lg z-50'
            )}
          >
            <input id={`${document}`} {...getInputProps()} type="file" />
            <p>{t('drop-files')}</p>
          </div>
        )}

        <DocumentsCard
          title={t('customerUploadedDocuments')}
          data={customerDocuments ?? []}
          color="blue"
        />
        <DocumentsCard
          title={t('caseWorkerUploadedDocuments')}
          data={caseWorkerDocuments ?? []}
          color="yellow"
        />
        <DocumentsCard
          title={t('dealerUploadedDocuments')}
          data={dealerDocuments ?? []}
          color="purple"
        />
        <DocumentsCard
          title={t('flowUploadedDocuments')}
          data={remainingDocuments ?? []}
          color="green"
        />
        <div
          className="cursor-pointer flex justify-center"
          onClick={() => open()}
        >
          <span className="text-xs ml-4 border-2 border-dashed border-gray-200 rounded-lg p-2 hover:border-gray-600 hover:shadow-lg">
            {t('click-upload-files')}
          </span>
        </div>
        {modal && (
          <Modal
            title={t('uploaded-file')}
            customWidth="80%"
            customHeight="80%"
            onClose={() => {
              setIsDragOver(0)
              setModal(false)
            }}
          >
            <UploadedDocumentHandler
              file={file}
              close={() => {
                setModal(false)
                setIsDragOver(0)
              }}
            />
          </Modal>
        )}
      </div>
    </FlowContext.Provider>
  )
}

import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { CenteredSpinner } from '@stacc/flow-ui-components'
import {
  ScreenContainer,
  LeftContent,
  RightContent,
  GroupPadded,
  GroupPaddedGrow
} from '../common/TaskViewContainerStyle'
import ContextGroup from '../common/task-context/ContextGroup'
import AssessmentForm from './AssessmentForm'
import htmlToPdfService from '../../services/html-to-pdf-se'
import {
  AssessmentScreenSeProps,
  FormData
} from '../../types/customerDeclaration'

const AssessmentScreenSe = ({
  close,
  flow,
  onComplete,
  onSave,
  schema,
  t,
  task,
  updateCase,
  user
}: AssessmentScreenSeProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [pdfDocument, setPdfDocument] = useState<string | undefined>()

  useEffect(() => {
    setIsLoading(true)
    const { context } = task
    const { customer, coCustomer, contextCustomerDeclarationType } = context

    if (contextCustomerDeclarationType === 'business') {
      htmlToPdfService.getHtmlBusinessSe(customer).then((result) => {
        if (result) {
          console.log(result)

          setPdfDocument(result)
          setIsLoading(false)
        } else {
          setPdfDocument(undefined)
          setIsLoading(false)
        }
      })
    } else {
      htmlToPdfService.getHtmlPrivate(customer, coCustomer).then((result) => {
        if (result) {
          setPdfDocument(result)
          setIsLoading(false)
        } else {
          setPdfDocument(undefined)
          setIsLoading(false)
        }
      })
    }
  }, [])

  function onFormSubmit(formData: FormData, callback = () => {}) {
    const assessmentData = {
      ...formData,
      name: user.profile.name,
      username: user.profile.username,
      sub: user.profile.sub
    }

    onComplete(
      task.taskId,
      assessmentData,
      () => {
        updateCase()
        close()
      },
      () => {
        callback()
      }
    )
  }

  function onFormSave(formData: FormData, callback = () => {}) {
    onSave(
      task.taskId,
      formData,
      () => {
        updateCase()
        close()
      },
      () => {
        callback()
      }
    )
  }

  const { context } = task
  const { customer } = context

  const riskClassOptions = schema.properties.riskClass.enum.map(
    (a: string, index: number) => ({
      value: a,
      label: t(schema.properties.riskClass.enumNames[index])
    })
  )

  return (
    <ScreenContainer>
      <LeftContent style={{ justifyContent: 'center' }}>
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <iframe
            title="PDF Document"
            style={{ width: '100%', height: '100%', border: 'none' }}
            srcDoc={pdfDocument}
          />
        )}
      </LeftContent>
      <RightContent>
        <GroupPaddedGrow>
          <ContextGroup
            title={customer.name}
            context={{
              id: customer.id,
              phone: customer.phone,
              email: customer.email
            }}
          />
        </GroupPaddedGrow>
        <GroupPadded>
          <AssessmentForm
            riskClassOptions={riskClassOptions}
            taskdata={task.data}
            onSubmit={(formData: FormData, cb: (() => void) | undefined) =>
              onFormSubmit(formData, cb)
            }
            onSave={(formData: FormData, cb: (() => void) | undefined) =>
              onFormSave(formData, cb)
            }
          />
        </GroupPadded>
      </RightContent>
    </ScreenContainer>
  )
}

export default withTranslation('inbox')(AssessmentScreenSe)

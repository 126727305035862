import { withTranslation } from 'react-i18next'

import NameBasedContextGroup from './NameBasedContextGroup'
import ContextGroup from './ContextGroup'
import { ContextProps } from '../../../types/common/task-context'

const Context = ({ context, t }: ContextProps) => {
  const {
    customer,
    coCustomer,
    guarantor,
    coGuarantor,
    financialInfo,
    asset,
    caseDetails,
    company,
    vendor
  } = context
  return (
    <>
      {caseDetails && (
        <NameBasedContextGroup
          context={{ name: caseDetails.referenceNumber }}
        />
      )}
      {vendor && <NameBasedContextGroup title={t('vendor')} context={vendor} />}
      {company && (
        <NameBasedContextGroup title={t('customer')} context={company} />
      )}
      {customer && (
        <NameBasedContextGroup title={t('customer')} context={customer} />
      )}
      {coCustomer && (
        <NameBasedContextGroup title={t('coCustomer')} context={coCustomer} />
      )}
      {guarantor && (
        <NameBasedContextGroup title={t('guarantor')} context={guarantor} />
      )}
      {coGuarantor && (
        <NameBasedContextGroup title={t('coGuarantor')} context={coGuarantor} />
      )}
      {financialInfo && (
        <ContextGroup title={t('financial info')} context={financialInfo} />
      )}
      {asset && <ContextGroup title={t('asset')} context={asset} />}
    </>
  )
}

export default withTranslation('inbox')(Context)

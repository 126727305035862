import React, { useState } from 'react'

import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { PrimaryButton, PrimaryProgressButton } from '@stacc/flow-ui-components'
import fileService from '../../../services/files'
import downloadLink from '../../../helpers/downloadLink'
import { ArchieveScreenProps, storedFiles } from '../../../types/archieve'

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`

const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const DocumentItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
`

const DocumentName = styled.div`
  padding-right: 15px;
`

const ArchieveFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;

  /*Hide the real input*/
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

const ArchieveScreen = ({
  onComplete,
  close,
  t,
  task,
  updateCase
}: ArchieveScreenProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  function onClick(fileId: string, fileName: string) {
    fileService
      .getFile(fileId)
      .then((result) => downloadLink(result?.file, fileName))
  }

  function getDocumentList(
    fileList: storedFiles,
    encryptedFiles?: storedFiles
  ) {
    const allFiles = [...fileList, ...(encryptedFiles || [])]

    const docList = allFiles.map((fileData) => {
      const isEncrypted = encryptedFiles
        ? encryptedFiles.some(
            (encryptedFile) => encryptedFile.storeID === fileData.storeID
          )
        : false

      return (
        <DocumentItem key={fileData.storeID}>
          <DocumentName style={isEncrypted ? { color: 'orange' } : {}}>
            {fileData.filename}
          </DocumentName>
          {isEncrypted && (
            <span style={{ color: 'orange' }}>(file is encrypted)</span>
          )}
          <PrimaryButton
            onClick={() => onClick(fileData.storeID, fileData.filename)}
          >
            Download
          </PrimaryButton>
        </DocumentItem>
      )
    })

    return docList
  }

  function handleComplete() {
    setIsSubmitting(true)
    onComplete(
      task.taskId,
      { archived: true },
      () => {
        updateCase()
        close()
        setIsSubmitting(false)
      },
      () => {
        setIsSubmitting(false)
      }
    )
  }

  return (
    <Screen>
      <ArchieveFormContainer>
        <DocumentList>
          {getDocumentList(
            task.context.storedFiles,
            task.context.encryptedFiles
          )}
        </DocumentList>
      </ArchieveFormContainer>
      <ButtonContainer>
        <PrimaryProgressButton
          title={t('Complete')}
          isLoading={isSubmitting}
          onClick={() => handleComplete()}
        >
          {t('Complete')}
        </PrimaryProgressButton>
      </ButtonContainer>
    </Screen>
  )
}

export default withTranslation('inbox')(ArchieveScreen)

import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import {
  PrimaryProgressButton,
  SecondaryButton
} from '@stacc/flow-ui-components'
import { Modal } from '@stacc/flow-ui-components'

const ButtonGroup = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ModalContent = styled.div`
  height: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

type ConfirmationViewProps = {
  title: string
  message?: string
  component?: React.ReactElement
  t: Function
  onConfirm: Function
  onAbort: Function
  onClose: Function
  isConfirming: boolean
}

const ConfirmationView = ({
  title,
  message,
  component,
  t,
  onConfirm,
  onAbort,
  onClose,
  isConfirming
}: ConfirmationViewProps) => (
  <Modal title={title} customWidth="30%" customHeight="30%" onClose={onClose}>
    <ModalBody>
      <ModalContent>
        {message && <p>{message}</p>}
        {component && <>{component}</>}
        <ButtonGroup>
          <SecondaryButton title={t('Abort')} onClick={onAbort}>
            {t('Abort')}
          </SecondaryButton>
          <PrimaryProgressButton
            title={t('Yes')}
            isLoading={isConfirming}
            onClick={onConfirm}
          >
            {t('Yes')}
          </PrimaryProgressButton>
        </ButtonGroup>
      </ModalContent>
    </ModalBody>
  </Modal>
)

export default withTranslation('inbox')(ConfirmationView)

import { TFunction } from 'i18next'

type CaseSummaryInfoProps = {
  financialInfo: any
  ncvsID: number
  t: TFunction
}

function CaseSummaryInfo({ financialInfo, ncvsID, t }: CaseSummaryInfoProps) {
  const formatDetails = (details: any[], titleKey: string) => (
    <>
      <p className="text-gray-800 uppercase font-medium pt-2">{t(titleKey)}</p>
      {details
        .filter(({ value }) => value !== null && value !== '' && value !== 0)
        .map(({ title, value, suffix }, index) => (
          <CaseSummaryDetailsList
            key={index}
            title={t(title)}
            value={value}
            suffix={suffix}
          />
        ))}
    </>
  )

  const paymentDetails = [
    {
      title: 'purchase-amount-mva',
      value: financialInfo.purchaseAmount,
      suffix: 'kr'
    },
    { title: 'import-duty', value: financialInfo.importDuty },
    {
      title: 'upfront-amount',
      value: financialInfo.upfrontAmount,
      suffix: 'kr'
    },
    {
      title: 'net-loan-amount',
      value: ncvsID === 545 ? financialInfo.creditPrice : 0,
      suffix: 'kr'
    },
    {
      title: 'registration-fee',
      value: financialInfo.registrationFee,
      suffix: 'kr'
    },
    {
      title: 'establishment-fee',
      value: financialInfo.establishmentFee,
      suffix: 'kr'
    },
    {
      title: 'financed-amount',
      value: financialInfo.financedAmount,
      suffix: 'kr'
    },
    { title: 'residual-value-amount', value: financialInfo.residualValueAmount }
  ]

  const monthlyCosts = [
    {
      title: 'financial-monthly-cost',
      value: financialInfo.financialMonthlyCost
    },
    {
      title: 'periodic-payment',
      value: financialInfo.periodicPayment,
      suffix: 'kr'
    },
    {
      title: 'calculated-service-agreement-value',
      value: financialInfo.calculatedServiceAgreementValue
    },
    {
      title: 'calculated-vat-added-extra-equipment-value',
      value: financialInfo.calculatedVatAddedExtraEquipmentValue
    },
    {
      title: 'total-leasing-payment',
      value: financialInfo.totalLeasingPayment
    },
    { title: 'periodic-payment-two', value: financialInfo.periodicPaymentTwo }
  ]

  const financialSetup = [
    {
      title: 'number-of-months',
      value: financialInfo.numberOfMonths,
      suffix: t('months')
    },
    { title: 'administration-fee', value: financialInfo.administrationFee },
    { title: 'term-fee', value: financialInfo.termFee },
    { title: 'periodicity', value: financialInfo.periodicity },
    {
      title: 'interest-rate-nom',
      value: financialInfo.interestRateNom,
      suffix: '%'
    },
    {
      title: 'interest-rat-eff',
      value: financialInfo.interestRateEff,
      suffix: '%'
    },
    { title: 'payment-plan-type', value: financialInfo.PaymentPlanType }
  ]

  const additionalInfo = [
    { title: 'pricelist-name', value: financialInfo.pricelistName },
    {
      title: 'vat-added-extra-equipment-value',
      value: financialInfo.vatAddedExtraEquipmentValue
    },
    {
      title: 'service-agreement-value',
      value: financialInfo.serviceAgreementValue
    },
    {
      title: 'insurance-comprehensive',
      value: financialInfo.insuranceComprehensive
    },
    { title: 'insurance-liability', value: financialInfo.insuranceLiability },
    {
      title: 'tire-management',
      value: financialInfo.tireManagement,
      suffix: 'kr'
    }
  ]

  return (
    <div className="flex flex-column text-xs h-full gap-4">
      <div className="w-full">
        {formatDetails(paymentDetails, 'payment-details')}
        {formatDetails(monthlyCosts, 'monthly-costs')}
      </div>
      <div className="h-full bg-gray-300 w-1" />
      <div className="w-full">
        {formatDetails(financialSetup, 'financial-setup')}
        {formatDetails(additionalInfo, 'additional-info')}
      </div>
    </div>
  )
}

type CaseSummaryDetailsListProps = {
  title: string
  value: number | string
  suffix?: string
}

export function CaseSummaryDetailsList({
  title,
  value,
  suffix = 'kr'
}: CaseSummaryDetailsListProps) {
  if (value === null || value === '' || value === 0) return null

  return (
    <data className="text-gray-500 text-xs flex flex-row items-center justify-between">
      <dt>{title}</dt>
      <dd>
        {typeof value === 'number'
          ? `${value.toLocaleString()} ${suffix}`
          : value}
      </dd>
    </data>
  )
}

export default CaseSummaryInfo

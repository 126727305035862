import { memo, useEffect, useRef, useState } from 'react'
import { PdfPageProps } from '../../../types/common/documentsViewer'
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist'

const PAGE_STATUS = {
  NONE: '',
  LOADING: 'loading',
  RENDERING: 'rendering',
  RENDERED: 'rendered'
}

const Page = ({ pdf, scale, index }: PdfPageProps) => {
  const [status, setStatus] = useState(PAGE_STATUS.NONE)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [queuePdf, setQueuePdf] = useState<PDFDocumentProxy | null>(null)

  useEffect(() => {
    updatePage(pdf)
  }, [pdf, scale])

  useEffect(() => {
    if (queuePdf !== null && status === PAGE_STATUS.RENDERED) {
      updatePage(queuePdf)
      setQueuePdf(null)
    }
  }, [status, queuePdf, setQueuePdf, updatePage])

  async function updatePage(pdf: PDFDocumentProxy | null) {
    if (status === PAGE_STATUS.LOADING || status === PAGE_STATUS.RENDERING) {
      if (pdf !== null) {
        setQueuePdf(pdf)
      }
      return
    }
    try {
      if (pdf === null) return
      setStatus(PAGE_STATUS.LOADING)
      const page: PDFPageProxy = await pdf.getPage(index)
      setStatus(PAGE_STATUS.RENDERING)
      await renderPage(page)
    } catch (e) {
      console.error(e)
    }
  }

  async function renderPage(page: PDFPageProxy) {
    const canvas = canvasRef.current
    const viewport = page.getViewport({ scale })
    if (canvas === null) return console.log('canvas is null')

    const context = canvas?.getContext('2d')
    if (context === null) return console.log('context is null')
    const { width, height } = viewport
    canvas.width = width
    canvas.height = height

    const renderTask: any = page.render({
      canvasContext: context,
      viewport
    })

    renderTask.promise.then(() => {
      setStatus(PAGE_STATUS.RENDERED)
    })
  }

  return <canvas ref={canvasRef} />
}

export default memo(Page)

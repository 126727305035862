import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import SelectDocumentsForm from './SelectDocumentsForm'
import {
  SelectDocumentsData,
  SelectDocumentsScreenProps
} from '../../../types/creditCheck'

const Screen = styled.div`
  display: flex;
  padding: 40px;
`

const Content = styled.div`
  width: 50%;
`

const SelectDocumentsScreen = ({
  t,
  close,
  onComplete,
  onSave,
  task,
  updateCase,
  user
}: SelectDocumentsScreenProps) => {
  function onFormSubmit(formData: SelectDocumentsData, callback = () => {}) {
    const selectData = {
      ...formData,
      name: user.profile.name,
      username: user.profile.username,
      sub: user.profile.sub
    }

    onComplete(
      task.taskId,
      selectData,
      () => {
        close()
        updateCase()
      },
      () => {
        callback()
      }
    )
  }

  function onFormSave(formData: SelectDocumentsData, callback = () => {}) {
    onSave(
      task.taskId,
      formData,
      () => {
        close()
        updateCase()
      },
      () => {
        callback()
      }
    )
  }

  const { context } = task

  const documentOptions = context.documentRequirements.map((doc) => ({
    label: t(doc.id),
    value: doc.id,
    checked: doc.status !== 'identified'
  }))

  return (
    <Screen>
      <Content>
        <SelectDocumentsForm
          documentOptions={documentOptions}
          taskdata={task.data}
          onSubmit={(
            formData: SelectDocumentsData,
            cb: (() => void) | undefined
          ) => onFormSubmit(formData, cb)}
          onSave={(
            formData: SelectDocumentsData,
            cb: (() => void) | undefined
          ) => onFormSave(formData, cb)}
        />
      </Content>
    </Screen>
  )
}

export default withTranslation('inbox')(SelectDocumentsScreen)

import { TFunction } from 'i18next'
import { CarIcon } from '../../icons/CarIcon'

type CaseSummaryObjectProps = {
  asset: any
  t: TFunction
}

const isValidValue = (value: any) => value !== null && value !== ''

function CaseSummaryObject({ asset, t }: CaseSummaryObjectProps) {
  return (
    <div className="flex flex-col gap-2 font-medium text-gray-700">
      {isValidValue(asset?.year) && asset.year !== 0 && (
        <p className="text-xs text-gray-500 uppercase">{asset.year}</p>
      )}
      {(isValidValue(asset?.brandName) || isValidValue(asset?.model)) && (
        <p>
          {asset?.brandName} {asset?.model}
        </p>
      )}
      {isValidValue(asset?.modelVariant) && (
        <div className="flex gap-2 items-center text-xs font-normal">
          <CarIcon size={20} />
          <p>{asset.modelVariant}</p>
        </div>
      )}
      <div className="w-full bg-gray-300 h-[2px] my-2" />
      <div>
        {isValidValue(asset?.registrationNumber) && (
          <data className="text-xs flex flex-row justify-between items-center pb-2">
            <dt>{t('registration-number')}:</dt>
            <dd>{asset.registrationNumber}</dd>
          </data>
        )}
        {isValidValue(asset?.maxYearlyMilage) &&
          asset.maxYearlyMilage !== 0 && (
            <data className="text-xs flex flex-row justify-between items-center pb-2">
              <dt>{t('max-yearly-milage')}:</dt>
              <dd>{asset.maxYearlyMilage} km</dd>
            </data>
          )}
        {isValidValue(asset?.priceExcessMilage) &&
          asset.priceExcessMilage !== 0 && (
            <data className="text-xs flex flex-row justify-between items-center">
              <dt>{t('price-excess-milage')}:</dt>
              <dd>{asset.priceExcessMilage} kr</dd>
            </data>
          )}
      </div>
    </div>
  )
}

export default CaseSummaryObject

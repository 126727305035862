/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import * as PdfJs from 'pdfjs-dist'
import { CenteredSpinner } from '@stacc/flow-ui-components'

import ZoomToolbar from './ZoomToolbar'
import Page from './PdfPage'
import { PdfViewerProps } from '../../../types/common/documentsViewer'
import { PDFDocumentProxy } from 'pdfjs-dist'

PdfJs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PdfJs.version}/pdf.worker.js`

const DEFAULT_SCALE = 1

interface PdfObject {
  pdf: PDFDocumentProxy | null
  scale: number
}

const PdfViewer = ({ fileId, file }: PdfViewerProps) => {
  const [pdfObject, setPdfObject] = useState<PdfObject>({
    pdf: null,
    scale: DEFAULT_SCALE
  })
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    getPdfDocument()
  }, [fileId])

  function getPdfDocument() {
    PdfJs.getDocument(file)
      .promise.then((pdf) => {
        setPdfObject({ pdf: pdf, scale: DEFAULT_SCALE })
      })
      .catch(() => {
        setIsError(true)
      })
  }
  const { pdf, scale } = pdfObject

  // eslint-disable-next-line no-underscore-dangle
  const numPages = pdf ? pdf._pdfInfo.numPages : 0

  if (isError) {
    return (
      <p>The PDF could not be loaded, possibly due to password protection.</p>
    )
  }

  if (!pdf) {
    return <CenteredSpinner />
  }

  return (
    <div>
      <ZoomToolbar
        scale={scale}
        onZoom={(newScale: number) => {
          setPdfObject({ pdf: pdf, scale: newScale })
        }}
      />
      <div>
        {Array.from(new Array(numPages)).map((page, i) => (
          <Page pdf={pdf} scale={scale} index={i + 1} key={`pdf-page-${i}`} />
        ))}
      </div>
    </div>
  )
}

export default PdfViewer

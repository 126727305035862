import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { Colors, FontStyles } from '@stacc/flow-ui-components'
import { Icons } from '@stacc/flow-ui-components'

import { validateDocument } from './audithelper'
import { DocumentsMenuProps } from '../../../types/audit'

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

interface MenuComponentProps {
  isActive: boolean
}

const MenuItem = styled.li<MenuComponentProps>`
  border-bottom: 1px solid ${Colors.Grey3};
  background-color: ${(props) =>
    props.isActive ? Colors.SeaLighter : Colors.Snow};
`

const activeIndicator = 3

const MenuButton = styled.button<MenuComponentProps>`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  ${FontStyles.Normal};
  width: 100%;
  padding: 15px 20px;
  line-height: 30px;
  background: transparent;
  border-left: ${(props) =>
    props.isActive ? `${activeIndicator}px solid ${Colors.SeaDark}` : ''};
  padding-left: ${(props) =>
    props.isActive ? `${20 - activeIndicator}px` : '20px'};
`

const DocumentsMenu = ({
  documents,
  displayErrors,
  currentIndex,
  t,
  onSelect
}: DocumentsMenuProps) => (
  <Menu>
    {documents.map((c: { name: string }, index: number) => {
      const isActive = currentIndex === index
      const hasError = displayErrors && !validateDocument(c)
      return (
        <MenuItem isActive={isActive} key={c.name}>
          <MenuButton
            autoFocus={index === 0}
            isActive={isActive}
            type="button"
            onClick={() => onSelect(index)}
          >
            {t(c.name)}
            {hasError && <Icons.ErrorCircle />}
          </MenuButton>
        </MenuItem>
      )
    })}
  </Menu>
)

export default withTranslation('inbox')(DocumentsMenu)

import styled from 'styled-components'

import { withTranslation } from 'react-i18next'

import { FontStyles } from '@stacc/flow-ui-components'
import { ContextGroupProps } from '../../../types/common/task-context'

const ContextGroupContainer = styled.div`
  padding: 0 0 10px 0;
`

const Title = styled.h6`
  :first-letter {
    text-transform: capitalize;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContentTitle = styled.p`
  ${FontStyles.Small};
  min-width: 40%;
  padding-right: 10px;

  :first-letter {
    text-transform: uppercase;
  }
`

const ContentValue = styled.div`
  flex-grow: 2;
`

const ContextGroup = ({ title, context, t }: ContextGroupProps) => (
  <ContextGroupContainer>
    <Title>{title}</Title>
    {Object.keys(context).map((key) => (
      <Content key={key}>
        <ContentTitle>{t(key)}</ContentTitle>
        <ContentValue>{context[key]}</ContentValue>
      </Content>
    ))}
  </ContextGroupContainer>
)

export default withTranslation('inbox')(ContextGroup)

import styled from 'styled-components'

import { PrimaryButton } from '@stacc/flow-ui-components'
import { FlowType } from '../types/filesTab'

const FilesTabContent = styled.div`
  display: flex;
  height: 100%;
`

const List = styled.ul`
  list-style: none;
  li {
    padding-bottom: 1em;
  }
`

const FilesTab = ({ flow }: FlowType) => (
  <FilesTabContent>
    <List>
      {flow.data.storedFiles.map((x) => (
        <li key={x.filename}>
          <PrimaryButton title={x.filename} fileId={x.storeID} />
        </li>
      ))}
    </List>
  </FilesTabContent>
)

export default FilesTab

import { TFunction } from 'i18next'
import ProgressBarItem from './ProgressBarItem'

type CaseSummaryProgressBarProps = {
  queueIds: string[]
  businessStatus: string
  contractStatus: string
  documentRequirements: any
  t: TFunction
  flowDefinitionId: string
  externalRequiredDocumentsOk: boolean
  allDocumentsAreOk: boolean
  hasCustomerDeclarationRequirement: boolean
  hasCoCustomer: boolean
}

const Line = (status: string) => {
  switch (status) {
    case 'in-progress':
      return <div className={`bg-yellow-500 w-full h-1 rounded-full`}></div>
    case 'rejected':
      return <div className={`bg-red-500 w-full h-1 rounded-full`}></div>
    case 'completed':
      return <div className={`bg-blue-500 w-full h-1 rounded-full`}></div>
    default:
      return <div className={`bg-gray-300 w-full h-1 rounded-full`}></div>
  }
}

function CaseSummaryProgressBarSE({
  queueIds,
  businessStatus,
  contractStatus,
  documentRequirements,
  flowDefinitionId,
  externalRequiredDocumentsOk,
  allDocumentsAreOk,
  hasCustomerDeclarationRequirement,
  hasCoCustomer,
  t
}: CaseSummaryProgressBarProps) {
  const eSignDocuments: any[] = documentRequirements
    ? Object.entries(documentRequirements)
        .map(([docName, docData]) => {
          if ((docData as any).toBeEsigned) {
            return {
              name: docName,
              dateSigned: (docData as any).dateSigned
                ? new Date((docData as any).dateSigned).toLocaleDateString()
                : ''
            }
          }
          return null
        })
        .filter(Boolean)
    : []

  const customerDeclaration = documentRequirements?.CUSTOMER_DECLARATION_SE
  const coCustomerDeclaration = documentRequirements?.CO_CUSTOMER_DECLARATION_SE

  const check_KYC = () => {
    if (hasCustomerDeclarationRequirement) {
      if (
        !customerDeclaration ||
        (customerDeclaration.status.toLowerCase() !== 'approved' &&
          customerDeclaration.status.toLowerCase() !== 'uploaded' &&
          customerDeclaration.status.toLowerCase() !== 'approvaldelayed')
      ) {
        return false
      }
    }

    if (hasCoCustomer) {
      if (
        coCustomerDeclaration &&
        coCustomerDeclaration.status.toLowerCase() !== 'approved' &&
        coCustomerDeclaration.status.toLowerCase() !== 'uploaded'
      ) {
        return false
      }
    }

    return true
  }

  let creditState: any = 'not-started'

  creditState =
    flowDefinitionId === 'credit-check' &&
    !['approved', 'finalized'].includes(contractStatus)
      ? 'in-progress'
      : creditState

  creditState = ['approved', 'finalized'].includes(contractStatus)
    ? 'completed'
    : creditState

  // Incoming - KYC - until customer declaration is uploaded
  let KYCStatus: any = 'not-started'

  KYCStatus =
    !check_KYC() &&
    contractStatus === 'finalized' &&
    flowDefinitionId !== 'credit-check'
      ? 'in-progress'
      : KYCStatus

  KYCStatus =
    check_KYC() &&
    contractStatus === 'finalized' &&
    flowDefinitionId !== 'credit-check'
      ? 'completed'
      : KYCStatus

  //Incoming/finalized - KYC and Document signing
  let signingStatus: any = 'not-started'

  const allDocumentsSigned = eSignDocuments?.every(
    (doc) => doc && doc.dateSigned !== ''
  )

  signingStatus =
    contractStatus === 'finalized' &&
    flowDefinitionId === 'document-control-se' &&
    !allDocumentsSigned
      ? 'in-progress'
      : signingStatus

  signingStatus =
    contractStatus && contractStatus === 'finalized' && allDocumentsSigned
      ? 'completed'
      : signingStatus

  //inbox/Control - Document control
  let controlStatus: any = 'not-started'

  controlStatus =
    contractStatus &&
    contractStatus === 'finalized' &&
    allDocumentsSigned &&
    externalRequiredDocumentsOk &&
    flowDefinitionId === 'document-control-se' &&
    !allDocumentsAreOk
      ? 'in-progress'
      : controlStatus

  controlStatus =
    contractStatus &&
    contractStatus === 'finalized' &&
    allDocumentsSigned &&
    externalRequiredDocumentsOk &&
    allDocumentsAreOk
      ? 'completed'
      : controlStatus

  //Select post control/Post control - Post control
  let postControlStatus: any = 'not-started'

  postControlStatus =
    businessStatus === 'post-control' ||
    businessStatus === 'select-post-control' ||
    businessStatus === 'audit'
      ? 'in-progress'
      : postControlStatus

  postControlStatus =
    businessStatus &&
    (businessStatus === 'archiving' || businessStatus === 'completed')
      ? 'completed'
      : postControlStatus

  //Audit
  let auditStatus: any = 'not-started'

  auditStatus =
    businessStatus && businessStatus === 'audit' ? 'in-progress' : auditStatus

  auditStatus =
    businessStatus &&
    (businessStatus === 'post-control' ||
      businessStatus === 'archiving' ||
      businessStatus === 'completed' ||
      queueIds.includes('dcse-perform-payout-and-start-contract') ||
      queueIds.includes('dcse-contract-and-car-registry-check'))
      ? 'completed'
      : auditStatus

  //Archiving
  let archivingStatus: any = 'not-started'

  archivingStatus =
    businessStatus && businessStatus === 'archiving'
      ? 'in-progress'
      : archivingStatus

  archivingStatus =
    businessStatus && businessStatus === 'completed'
      ? 'completed'
      : archivingStatus

  const hoverComponentSignedDocuments = () => {
    if (eSignDocuments?.length === 0) {
      return null
    }

    return (
      <ul>
        {(eSignDocuments as { name: string; dateSigned: string }[]).map(
          ({ name, dateSigned }) => (
            <li key={name} className="text-xs">
              <span className="text-gray-800">{t(name)}</span>
              <span className="text-gray-500">
                {dateSigned
                  ? ` - ${t('progress-bar-signed-at')}: ${dateSigned}`
                  : ` - ${t('progress-bar-not-signed')}`}
              </span>
            </li>
          )
        )}
      </ul>
    )
  }

  return (
    <div className="flex h-10 justify-center items-center space-x-4 px-10 pb-8">
      {/* Credit status */}
      <ProgressBarItem status={creditState} label={t('progress-bar-credit')} />
      {/* KYC */}
      {Line(KYCStatus)}
      <ProgressBarItem status={KYCStatus} label="KYC" />
      {/* Document signing */}
      {Line(signingStatus)}
      <ProgressBarItem
        status={signingStatus}
        hoverChild={hoverComponentSignedDocuments()}
        label={t('progress-bar-document-signing')}
      />
      {/*Document control */}
      {Line(controlStatus)}
      <ProgressBarItem
        status={controlStatus}
        label={t('progress-bar-document-control')}
      />
      {/* Post control */}
      {Line(postControlStatus)}
      <ProgressBarItem
        status={postControlStatus}
        label={t('progress-bar-post-control')}
      />
      {/* Audit */}
      {Line(auditStatus)}
      <ProgressBarItem status={auditStatus} label={t('progress-bar-audit')} />
      {/* Archiving */}
      {Line(archivingStatus)}
      <ProgressBarItem
        status={archivingStatus}
        label={t('progress-bar-archiving')}
      />
    </div>
  )
}

export default CaseSummaryProgressBarSE

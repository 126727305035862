import styled from 'styled-components'
import nordeaLogo from '../resources/nordea-logo.svg'

const IconFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 60px;
  width: 60px;
  border-radius: 30px;
  margin-top: 20px;
  overflow: hidden;
  background-color: #0000a0;
`

const AuthenticationIcon = () => (
  <IconFrame>
    <div style={{ marginTop: 4 }} className="icon">
      <img src={nordeaLogo} alt="logo" style={{ height: 40 }} />
    </div>
  </IconFrame>
)

export default AuthenticationIcon

import CaseSummaryStakeholders from './CaseSummaryStakeholders'
import CaseSummaryProgressBarSE from './CaseSummaryProgressBarSE'
import CaseSummaryProgressBarNO from './CaseSummaryProgressBarNO'
import { formatDateTime } from '../../utils/dateFormatter'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import task from '../../services/task'
import findMatchingQueues from '../../helpers/queueConfigurations'
import CollapsibleSection from './CollapsibleSection'

const { featureToggles = [] } = window.stacc_env
const isSwedenPortal = featureToggles.includes('se-portal')

function CaseSummary({ caseData, flowDefinitionId, t }: any) {
  const {
    flowId,
    customer,
    coCustomer,
    vendor,
    salesPerson,
    businessStatus,
    financialInfo,
    asset,
    caseDetails,
    documentRequirements,
    externalRequiredDocumentsOk,
    allDocumentsAreOk,
    hasCustomerDeclarationRequirement,
    hasSourceOfFundsRequirement,
    product
  } = caseData.data

  const contractStatus = caseDetails?.contractStatus
  const subContractStatus = caseDetails?.subContractStatus
  const contractNumber = caseDetails?.contractNumber

  const updatedAt = caseData?.updatedAt

  const ncvsID = product?.ncvsId

  const formattedDate = formatDateTime(new Date(updatedAt))

  const {
    data: tasks,
    refetch,
    isFetching
  } = useQuery({
    queryKey: ['tasks', flowId],
    queryFn: async () => await task.fetchTasks(flowId)
  })

  useEffect(() => {
    refetch()
  }, [caseData])

  const queueNames = findMatchingQueues(tasks, caseData).map(
    (queue: any) => queue.name
  )

  const queueIds = findMatchingQueues(tasks, caseData).map(
    (queue: any) => queue.taskId
  )

  const hasAssetInfo =
    (asset?.year !== null && asset?.year !== 0) ||
    (asset?.brandName !== null && asset?.brandName !== '') ||
    (asset?.model !== null && asset?.model !== '') ||
    (asset?.modelVariant !== null && asset?.modelVariant !== '') ||
    (asset?.registrationNumber !== null && asset?.registrationNumber !== '') ||
    (asset?.maxYearlyMilage !== null && asset?.maxYearlyMilage !== 0) ||
    (asset?.priceExcessMilage !== null && asset?.priceExcessMilage !== 0)

  const isAssessment = businessStatus === 'assessment'

  const shouldShowProgressBar = [
    'document-control',
    'document-control-se',
    'credit-check'
  ].includes(flowDefinitionId)

  return (
    <>
      {(businessStatus || contractStatus) &&
        !isAssessment &&
        shouldShowProgressBar &&
        (isSwedenPortal ? (
          <CaseSummaryProgressBarSE
            queueIds={queueIds}
            businessStatus={businessStatus?.toLowerCase()}
            contractStatus={contractStatus?.toLowerCase()}
            documentRequirements={documentRequirements}
            externalRequiredDocumentsOk={externalRequiredDocumentsOk}
            flowDefinitionId={flowDefinitionId}
            allDocumentsAreOk={allDocumentsAreOk}
            hasCustomerDeclarationRequirement={
              hasCustomerDeclarationRequirement
            }
            hasCoCustomer={!!coCustomer}
            t={t}
          />
        ) : (
          <CaseSummaryProgressBarNO
            businessStatus={businessStatus?.toLowerCase()}
            contractStatus={contractStatus?.toLowerCase()}
            subContractStatus={subContractStatus?.toLowerCase()}
            documentRequirements={documentRequirements}
            externalRequiredDocumentsOk={externalRequiredDocumentsOk}
            flowDefinitionId={flowDefinitionId}
            allDocumentsAreOk={allDocumentsAreOk}
            hasCustomerDeclarationRequirement={
              hasCustomerDeclarationRequirement
            }
            hasSourceOfFundsRequirement={hasSourceOfFundsRequirement}
            hasCoCustomer={!!coCustomer}
            t={t}
          />
        ))}
      <div
        className="flex justify-between items-center px-2 h-5 text-xs text-gray-800"
        style={{ marginBottom: 0 }}
      >
        <p className="flex items-center font-medium">
          <span className="text-gray-500">
            {'Queue(s):'} {isFetching ? 'Loading...' : queueNames.join(', ')}
          </span>
        </p>
        <p className="text-gray-500">
          {t('last-updated')}: {formattedDate}
        </p>
      </div>
      <div className="grid gap-4">
        <div className="text-black h-full grid grid-cols-12 gap-4">
          {(customer || coCustomer || vendor || salesPerson) && (
            <div className="p-4 col-span-12 rounded-xl shadow-lg flex flex-col w-full border border-gray-300">
              <CaseSummaryStakeholders
                customer={customer}
                coCustomer={coCustomer}
                vendor={vendor}
                salesPerson={salesPerson}
                contractNumber={contractNumber}
                t={t}
              />
            </div>
          )}
        </div>
        {financialInfo && (
          <CollapsibleSection
            asset={asset}
            financialInfo={financialInfo}
            hasAssetInfo={hasAssetInfo}
            t={t}
            ncvsID={ncvsID}
          />
        )}
      </div>
    </>
  )
}

export default CaseSummary

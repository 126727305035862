import { TFunction } from 'i18next'

type Person = {
  id: string
  name: string
  email: string
  address?: string
  postalAddress?: string
}

type Vendor = {
  id: string
  name: string
}

type CaseSummaryStakeholdersProps = {
  customer?: Person
  coCustomer?: Person
  vendor?: Vendor
  salesPerson?: Person
  contractNumber?: number
  t: TFunction
}

function CaseSummaryStakeholders({
  customer,
  coCustomer,
  vendor,
  salesPerson,
  contractNumber,
  t
}: CaseSummaryStakeholdersProps) {
  const stakeholders = [customer, coCustomer, salesPerson, vendor]
  const definedStakeholders = stakeholders.filter(Boolean)
  const justifyClass =
    definedStakeholders.length <= 2 ? 'justify-start' : 'justify-between'

  const renderStakeholderBlock = (
    title: string,
    name: string,
    email: string,
    id?: string,
    address?: string,
    postalAddress?: string
  ) => (
    <div>
      <p className="text-gray-800 uppercase font-medium">{title}</p>
      <p className="text-gray-500">
        {name}
        {id && ` (${id})`}
      </p>
      <p className="text-gray-500">{email}</p>
      {address && <p className="text-gray-500">{address}</p>}
      {postalAddress && <p className="text-gray-500">{postalAddress}</p>}
    </div>
  )

  return (
    <div className={`flex flex-row gap-6 text-xs w-full ${justifyClass}`}>
      {customer &&
        renderStakeholderBlock(
          t('case-summary-customer'),
          customer.name,
          customer.email,
          customer.id,
          customer.address,
          customer.postalAddress
        )}
      {coCustomer &&
        renderStakeholderBlock(
          t('case-summary-coCustomer'),
          coCustomer.name,
          coCustomer.email,
          coCustomer.id,
          coCustomer.address,
          coCustomer.postalAddress
        )}
      {salesPerson &&
        renderStakeholderBlock(
          t('case-summary-sales-person'),
          salesPerson.name,
          salesPerson.email
        )}
      {vendor &&
        renderStakeholderBlock(
          t('case-summary-vendor'),
          vendor.name,
          vendor.id
        )}
      {contractNumber && (
        <div>
          <p className="text-gray-800 uppercase font-medium">
            {t('contract-number')}
          </p>
          <p className="text-gray-500">{contractNumber}</p>
        </div>
      )}
    </div>
  )
}

export default CaseSummaryStakeholders

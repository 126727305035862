import { PrimaryProgressButton } from '@stacc/flow-ui-components'
import { Text } from '@stacc/flow-ui-components'
import { Colors } from '@stacc/flow-ui-components'
import { Icons } from '@stacc/flow-ui-components'
import { Formik } from 'formik'
import { useState } from 'react'
import { Link, Route } from 'react-router-dom'
import styled from 'styled-components'
import UserView from './UserView'
import NewUserView from './NewUserView'
import DeleteUserView from './DeleteUserView'

import { deleteStakeholder, getStakeholders } from '../../services/stakeholders'
import UserResult from './UserResult'
import { Stakeholder as StakeholderUser } from '../../types/adminDealers'

const Screen = styled.div`
  width: 100%;
  padding: 3em;
  padding-bottom: 0;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`

const AddNewUser = styled(Link)`
  display: flex;
  align-items: center;
  align-content: space-between;
  text-decoration: none;
`

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 2em 0em;

  @media (min-width: 1224px) {
    width: 80%;
  }
  @media (min-width: 1824px) {
    width: 60%;
  }
`

const RowItem = styled.div`
  width: 100%;
  background-color: ${Colors.Grey4};
  border: 1px solid ${Colors.Grey3};
  padding: 2em;
  margin-bottom: 1em;
  position: relative;
`

const SearchInputs = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  > * {
    width: 30%;
    margin-right: 3%;
  }
`

const ButtonInputs = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  > * {
    margin-right: 3%;
    margin-top: 1em;
  }
`

const SubmitSearch = styled.div`
  margin-top: 1em;
`

const DealersScreen = () => {
  const [stakeholders, setStakeholders] = useState<StakeholderUser[]>([])

  const deleteHandler = async (userId: string) => {
    await deleteStakeholder(userId)
    setStakeholders(stakeholders.filter((sh) => sh.id !== userId))
  }

  const fetchStakeholders = async (uniqname: string, vendorId: string) => {
    let stakeholder = []
    if (!vendorId) {
      return (
        await getStakeholders({
          type: 'Person',
          uniqname,
          'access_object.vendorId': vendorId
        })
      ).data
    }
    stakeholder = (
      await getStakeholders({
        type: 'Person',
        uniqname,
        'access_object.vendorId': undefined
      })
    ).data
    stakeholder = stakeholder.filter(
      (sh: { access_object: { vendorId: string | any[] }[] }) => {
        if (sh.access_object) {
          for (let i = 0; i < sh.access_object.length; i++) {
            if (sh.access_object[i].vendorId.includes(vendorId)) {
              return sh
            }
          }
        }
        return null
      }
    )
    stakeholder = stakeholder.filter((sh: any) => sh)
    return stakeholder
  }

  return (
    <Screen>
      <ContentGroup>
        <RowItem>
          <h1>Dealers administration</h1>
          <Formik
            onSubmit={async (values, { setSubmitting }) => {
              // Remove empty values including { null | "" | undefined }
              const { uniqname, vendorId } = Object.fromEntries(
                Object.entries(values).filter(([, v]) => !!v)
              )
              setStakeholders(await fetchStakeholders(uniqname, vendorId))
              setSubmitting(false)
            }}
            initialValues={{
              type: 'Person',
              uniqname: ''
            }}
          >
            {({ isSubmitting, handleBlur, handleChange, handleSubmit }) => (
              <>
                <SearchInputs>
                  <Text
                    name="uniqname"
                    label="Salesperson ID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Text
                    name="vendorId"
                    label="Vendor ID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </SearchInputs>
                <ButtonInputs>
                  <SubmitSearch>
                    <PrimaryProgressButton
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                    >
                      Search
                    </PrimaryProgressButton>
                  </SubmitSearch>
                  <AddNewUser to="/admin/dealers/new">
                    <Icons.Add />
                    {' Add new user'}
                  </AddNewUser>
                </ButtonInputs>
              </>
            )}
          </Formik>
        </RowItem>
        {stakeholders.map((stakeholder) => (
          <RowItem key={stakeholder.id}>
            <UserResult stakeholder={stakeholder} />
          </RowItem>
        ))}
      </ContentGroup>
      <Route exact path="/admin/dealers/new">
        <NewUserView />
      </Route>
      <Route exact path="/admin/dealers/user/:userId">
        <UserView />
      </Route>
      <Route exact path="/admin/dealers/delete/:userId">
        <DeleteUserView deleteHandler={deleteHandler} />
      </Route>
    </Screen>
  )
}

export default DealersScreen

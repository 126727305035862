import styled from 'styled-components'
import { CheckboxProps } from '../../types/common/common'

const CheckboxLabel = styled.label`
  input {
    margin-right: 10px;
    height: 15px;
    width: 15px;
  }
`

const Checkbox = ({
  id,
  label,
  name,
  value,
  checked,
  onChange
}: CheckboxProps) => (
  <CheckboxLabel htmlFor={id}>
    <input
      id={id}
      name={name}
      type="checkbox"
      value={value}
      checked={checked}
      onChange={onChange}
    />
    {label}
  </CheckboxLabel>
)

export default Checkbox

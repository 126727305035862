import React, { useState } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Formik, ErrorMessage } from 'formik'

import {
  PrimaryProgressButton,
  SecondaryButton
} from '@stacc/flow-ui-components'
import { Colors, FontStyles } from '@stacc/flow-ui-components'
import { RadioButtons } from '@stacc/flow-ui-components'
import { ApprovalFormProps, FormValues } from '../../../types/common/approval'

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: 1px solid ${Colors.Sea};
  box-sizing: border-box;
  border-radius: 4px;
  ${FontStyles.Normal};
  padding: 10px;
`

const FormGroup = styled.div`
  padding: 10px 0px 0px 0px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse; /*Primarybutton is placed to the right but still triggered on enter key*/
`

const Error = styled.div`
  color: ${Colors.Ferrari};
  padding-bottom: 20px;
`

const ApprovalForm = ({
  approvalOptions,
  taskdata,
  onSubmit,
  onSave,
  t
}: ApprovalFormProps) => {
  const [isSaving, setIsSaving] = useState(false)

  function handleSave(e: React.MouseEvent<HTMLElement>, values: FormValues) {
    e.preventDefault()
    setIsSaving(true)

    onSave(values, () => {
      setIsSaving(false)
    })
  }

  return (
    <div>
      <h6>{t('Decision')}</h6>
      <Formik
        initialValues={{
          approval: taskdata?.approval,
          comment: taskdata?.comment
        }}
        validate={(values) => {
          const errors: { approval: string; comment: string } | any = {}
          if (!values.approval) {
            errors.approval = t('Please provide a decision')
          }
          if (
            values.approval &&
            values.approval !== 'approved' &&
            (!values.comment || values.comment.length < 1)
          ) {
            errors.comment = t('Please provide reasons')
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, () => {
            setSubmitting(false)
          })
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <RadioButtons
                options={approvalOptions}
                name="approval"
                onChange={handleChange}
                checkedValue={values.approval}
              />
            </FormGroup>
            <FormGroup>
              <TextArea
                name="comment"
                rows={5}
                placeholder={t('Optionally reasons')}
                value={values.comment}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ErrorMessage name="approval" component={Error} />
              <ErrorMessage name="comment" component={Error} />
            </FormGroup>
            <FormGroup>
              <ButtonGroup>
                <PrimaryProgressButton
                  type="submit"
                  isLoading={isSubmitting || isSaving}
                >
                  {t('Complete')}
                </PrimaryProgressButton>
                <SecondaryButton
                  disabled={isSaving || isSubmitting}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    handleSave(e, values)
                  }
                >
                  {t('Save and close')}
                </SecondaryButton>
              </ButtonGroup>
            </FormGroup>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default withTranslation('inbox')(ApprovalForm)

import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'

import { Modal } from '@stacc/flow-ui-components'
import { PrimaryProgressButton } from '@stacc/flow-ui-components'

import { editStakeholder, getStakeholder } from '../../services/stakeholders'
import StakeholderForm from './StakeholderForm'
import { Stakeholder, UserViewParams } from '../../types/adminDealers'

const FormGroup = styled.div`
  padding: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse; /*Primarybutton is placed to the right but still triggered on enter key*/
`

const UserView = () => {
  const { userId } = useParams<UserViewParams>()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [user, setUser] = useState<Stakeholder | null>(null)

  const modalRef = useRef<HTMLDialogElement>(null)
  const errorDiv = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsLoading(true)
    setError(false)
    getStakeholder(userId)
      .then((result) => {
        setUser(result.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setError(true)
      })
  }, [userId])

  return (
    <Modal
      ref={modalRef}
      title="User"
      onClose={() => history.push('/admin/dealers')}
    >
      {isLoading && <div>Loading...</div>}
      {error && <div>Could not find user...</div>}
      {user && (
        <StakeholderForm
          initialValues={{
            nid: user.username,
            name: user.name,
            roles: user.access_object
          }}
          onSubmit={async (values: {
            nid: string
            name: string
            roles: string[]
          }) => {
            try {
              console.log(values)
              await editStakeholder({ userId, values })
            } catch (e) {
              if (errorDiv.current !== null) {
                errorDiv.current.innerText = `Error: ${e}`
              }
              return
            }
            modalRef.current?.close()
          }}
        >
          {({
            isSubmitting,
            dirty
          }: {
            isSubmitting: boolean
            dirty: boolean
          }) => (
            <FormGroup>
              <ButtonGroup>
                <PrimaryProgressButton
                  type="submit"
                  disabled={!dirty}
                  isLoading={isSubmitting}
                >
                  Update
                </PrimaryProgressButton>
              </ButtonGroup>
            </FormGroup>
          )}
        </StakeholderForm>
      )}
      <div ref={errorDiv} />
    </Modal>
  )
}

export default UserView
